import React from 'react'
import Layout from '../components/layouts'
import * as ds from '../uiLibrary'
import styled from 'styled-components'
import { InnerPageHero } from '../components/heroes'
import { graphql } from 'gatsby'
import ReactMarkdown from "react-markdown";
import HeadMeta from '../components/HeadMeta'

const StyledDate = styled(ds.Eyebrow)`
  color: ${ds.colors.typography.darkTertiary};
  font-size: ${ds.typeScale.t3};
`

export default function Template({ data }) {
  const page = data.allFaqJson.edges[0].node

  const Articles = props => (
    <div key={props.i}>
      <ds.Dropdown position={props.question} render={() => <>
        <ReactMarkdown>{props.answer}</ReactMarkdown>
        <StyledDate>Last updated on: {props.date}</StyledDate>
      </>} />
    </div>
  )

  const Category = props => {
    return (
      <div key={props.i}>
        <ds.Dropdown fontSize={ds.fontSizes[5]} position={props.title} render={() => <>
          {props.articles.map((a, i) => (
            <Articles {...a} key={i} />
          ))}
        </>
        } />
      </div>
    )
  }

  return (
    <Layout>
      <HeadMeta
        pageTitle={page.pageTitle || 'FAQ - Tonit'}
        pageDescription={page.pageDescription}
        socialTitle={page.socialTitle}
        socialDescription={page.socialDescription}
        socialImage={page.socialImage}
      />
      <InnerPageHero url={page.heroBackground} alt="Tonit Motorcycle FAQ">
        <ds.FixedWidthRow>
          <ds.Column span={8}>
            <ds.Headline fontSize={[ds.typeScale.t6, ds.typeScale.t8]}>
              {page.heroHeading}
            </ds.Headline>
            <ds.H2>{page.heroBody}</ds.H2>
          </ds.Column>
        </ds.FixedWidthRow>
      </InnerPageHero>
      <ds.Background bg={ds.colors.white}>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            {page.categories.map((c, i) => (
              <Category {...c} key={i} />
            ))}
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allFaqJson {
      edges {
        node {
          heroHeading
          heroBody
          heroBackground
          categories {
            title
            articles {
              answer
              date
              question
            }
          }
          pageTitle
          pageDescription
          socialTitle
          socialDescription
          socialImage
        }
      }
    }
  }
`
